import { LANDING_DESCRIPTION, LANDING_TITLE } from '@/utils/messages';
import { default as SuccessIcon } from '@mui/icons-material/CheckCircleRounded';
import { PasswordlessLogin } from '@verifime/cognito-passwordless-auth';
import { Landing } from '@verifime/components';
import { useRouter } from 'next/router';

export default function LoginPage() {
  const router = useRouter();

  const signInRedirect = () => {
    router.push('/credentialSetup');
  };

  return (
    <Landing title={LANDING_TITLE} description={LANDING_DESCRIPTION}>
      <PasswordlessLogin signInRedirect={signInRedirect}>
        <SuccessIcon color="success" />
      </PasswordlessLogin>
    </Landing>
  );
}
